import { ChakraProvider, extendTheme } from "@chakra-ui/react";
import Fonts from "../fonts/Fonts";
import { FlagsmithProvider } from "flagsmith/react";
import flagsmith from "flagsmith/isomorphic";
import { useFlags } from "flagsmith/react";
import EnterPassword from "../components/EnterPassword";
import { useEffect, useState } from "react";
import Custom404 from "./404";
import { useRouter } from "next/router";


function AppWrapper({
  Component,
  pageProps,
  passwordCorrect,
  setPasswordCorrect,
}) {
  const flags = useFlags(["password_enabled"]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (flags.password_enabled.enabled) {
      const password = localStorage.getItem("foundPassword");

      const checkPassword = async (password) => {
        const response = await fetch("/api/check-password", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            password,
          }),
        });

        const content = await response.json();

        if (content.verification) {
          setPasswordCorrect(true);
          setLoading(false);
        } else {
          setPasswordCorrect(false);
          setLoading(false);
        }
      };

      if (password) {
        checkPassword(password);
      } else {
        setLoading(false);
      }
    }
  }, []);

  if (flags.password_enabled.enabled) {
    if (loading) return <div>Loading</div>;

    return !passwordCorrect ? (
      <EnterPassword handleClose={setPasswordCorrect} />
    ) : (
      <Component {...pageProps} />
    );
  } else {
    return <Component {...pageProps} />;
  }
}

function MyApp({ Component, pageProps, flagsmithState }) {
  const path = useRouter().asPath;
  return (
    path.includes("/api/") ? <Component/> : <Custom404 />
  );
}

MyApp.getInitialProps = async () => {
  await flagsmith.init({
    environmentID: process.env.NEXT_PUBLIC_FLAGSMITH_KEY,
  });
  return { flagsmithState: flagsmith.getState() };
};

export default MyApp;
